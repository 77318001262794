import sharedUtil from '../../build/shared/util';

const util = {
    ...sharedUtil,
    sagaDelay: function* delay(time) {
        yield new Promise(resolve => setTimeout(resolve, time));
    },
    toReadableDate(date) {
        if (!util.isDefined(date)) {
            return;
        }
        return date
            .substring(0, 10)
            .replace('-', ' / ')
            .replace('-', ' / ');
    }
};

export default util;
