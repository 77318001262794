import React, { Component } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import {
    Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import PrivateRoute from './containers/private_route';

import store from './state/create_store';
import history from './services/history';
import './styles.css';

import { userTypes } from './state/redux/user';

import Flex from './components/flex';
import Page from './components/page';
import pages from './pages';
import api from './services/api';

const {
    Home,
    Account,
    Changelog,
    SignUp,
    Team
} = pages;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
    }

    static async loadUser() {
        try {
            const response = await api.fetchCurrentUser();
            store.dispatch({ type: userTypes.fetchCurrentUserSuccess, data: response.data });
        } catch (e) {
            store.dispatch({ type: userTypes.fetchCurrentUserFail, data: e.response.data });
        }
    }

    static async loadSomethingElse() {
        try {
            // load something else
        } catch (e) {
            // catch errors
        }
    }

    // preloading before render
    async componentDidMount() {
        const { loadUser, loadSomethingElse } = App;
        try {
            await loadUser();
            await loadSomethingElse();
        } finally {
            this.setState({ loaded: true });
        }
    }

    static renderLoadingPage() {
        return (
            <Page title='Age of Chim'>
                <Flex />
            </Page>
        );
    }

    render() {
        const { loaded } = this.state;
        const { renderLoadingPage } = App;
        return (
            <Provider store={store}>
                <Router history={history}>
                    {loaded
                        ? (
                            <Switch>
                                <Route exact path='/' component={Home} />
                                <PrivateRoute path='/account' component={Account} />
                                <Route path='/changelog' component={Changelog} />
                                <Route path='/signup' component={SignUp} />
                                <Route path='/team' component={Team} />
                                <Redirect to='/' />
                            </Switch>

                        ) : renderLoadingPage()
                    }
                </Router>
            </Provider>
        );
    }
}

const app = <App />;
render(app, document.getElementById('root'));
