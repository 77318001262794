import { all } from 'redux-saga/effects';

import userSagas from './user';
import authSagas from './auth';

export default function* sagas() {
    yield all([
        ...userSagas(),
        ...authSagas()
    ]);
}
