import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Flex from '../../../../components/flex';
import AccountForm from '../account_form';

import './styles.css';

import { userActions, userSelectors } from '../../../../state/redux/user';
import { utilsSelectors } from '../../../../state/redux/utils';

import constants from '../../../../constants';

const { IMAGE } = constants;

class ChimProfile extends Component {
    componentDidMount() {
        this.props.fetchUserAccount();
    }

    render() {
        const { displayName } = this.props.account;
        const chimProfPic = `${this.props.assetUrl}/${IMAGE.CHIM_PROF_PIC}`;
        return (
            <Flex
                flex={10}
                flexDirection='column'
                style={{ margin: '2.5%' }}
            >
                <Flex
                    alignItems='center'
                    style={{ margin: '2.5%' }}
                >
                    <img src={chimProfPic} alt='' className='chimg' />
                    <h2 className='display-name'>{displayName}</h2>
                </Flex>
                <AccountForm username={this.props.username} account={this.props.account} />
            </Flex>
        );
    }
}

ChimProfile.propTypes = {
    fetchUserAccount: PropTypes.func.isRequired,
    account: PropTypes.shape({
        displayName: PropTypes.string,
        email: PropTypes.string,
        dateCreated: PropTypes.string
    }).isRequired,
    assetUrl: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    return {
        username: userSelectors.getCurrentUser(state).username,
        account: userSelectors.getUserAccount(state),
        assetUrl: utilsSelectors.getAssetUrl(state)
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...userActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChimProfile);
