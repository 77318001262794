import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoginForm from '../login_form';
import { PanelSmall } from '../../../../components/panel';
import AuthNever from '../../../../containers/auth_never';
import Flex from '../../../../components/flex';

import { authSelectors, authActions } from '../../../../state/redux/auth';

import './styles.css';

const PlayPanel = (props) => {
    const { logInMessage, showPanel } = props;

    const renderPanel = () => {
        const panelStyles = {
            opacity: 0,
            maxWidth: '400px',
            borderRadius: '10px',
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
            background: 'transparent'
        };
        const panelClasses = showPanel ? 'fade-in' : 'fade-out';

        return (
            <PanelSmall style={panelStyles} class={panelClasses}>
                <Flex flex={1} flexDirection='column' justifyContent='center' class='play-phrase'>
                    <div className='flex-start'>Adventure awaits</div>
                    <div className='flex-end'>in the age of Chim...</div>
                </Flex>
                <LoginForm />
                <Flex
                    flex={0.25}
                    textAlign='center'
                    justifyContent='center'
                    class='fade-in'
                    style={{ margin: '2.5%' }}
                >
                    {logInMessage}
                </Flex>
            </PanelSmall>
        );
    };

    return (
        <Flex flex={10} justifyContent='center'>
            {showPanel
                ? (
                    <AuthNever>
                        {renderPanel()}
                    </AuthNever>
                ) : null
            }
        </Flex>
    );
};

PlayPanel.propTypes = {
    showPanel: PropTypes.bool.isRequired,
    logInMessage: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    return {
        logInMessage: authSelectors.getLogInMessage(state)
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...authActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlayPanel);
