import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { userSelectors } from '../../state/redux/user';
import { authActions } from '../../state/redux/auth';
import navigation from '../../services/navigation';

import Page from '../../components/page';
import TitleBanner from './components/title_banner';
import PlayPanel from './components/play_panel';

import './styles.css';

class Home extends Component {
    constructor(props) {
        super(props);
        this.showPlayPanel = this.showPlayPanel.bind(this);
        this.hidePlayButton = this.hidePlayButton.bind(this);
        this.handlePlayClick = this.handlePlayClick.bind(this);
        this.state = {
            showPlayPanel: false,
            showPlayButton: true
        };
    }

    componentWillUnmount() {
        this.props.clearLogInMessage();
    }

    showPlayPanel() {
        this.setState({ showPlayPanel: true });
    }

    hidePlayButton() {
        this.setState({ showPlayButton: false });
    }

    handlePlayClick() {
        if (this.props.isLoggedIn) {
            navigation.toGame();
            return;
        }

        if (!this.state.showPlayPanel) {
            this.showPlayPanel();
            this.hidePlayButton();
        }
    }

    render() {
        const { showPlayButton, showPlayPanel } = this.state;
        const { isLoggedIn } = this.props;
        return (
            <Page
                title='Home | Age of Chim'
            >
                <TitleBanner
                    handlePlayClick={this.handlePlayClick}
                    showButton={isLoggedIn || showPlayButton}
                />
                <PlayPanel showPanel={showPlayPanel} />
            </Page>
        );
    }
}

Home.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    clearLogInMessage: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: userSelectors.getIsLoggedIn(state),
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...authActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
