import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button } from '../../../../components/button';
import Flex from '../../../../components/flex';
import Form from '../../../../components/form';
import TextInput from '../../../../components/form/components/text_input';

import './styles.css';

import { authActions } from '../../../../state/redux/auth';

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.onUsernameInput = this.onUsernameInput.bind(this);
        this.onPasswordInput = this.onPasswordInput.bind(this);
        this.onSubmitLogin = this.onSubmitLogin.bind(this);
        this.onSubmitPlay = this.onSubmitPlay.bind(this);
        this.state = {
            username: '',
            password: ''
        };
    }

    componentDidMount() {
        this.usernameInput.focus();
    }

    onUsernameInput(value) {
        this.setState({ username: value });
    }

    onPasswordInput(value) {
        this.setState({ password: value });
    }

    enableSubmit() {
        const { username, password } = this.state;
        return (username.length && password.length);
    }

    onSubmitLogin(e) {
        e.preventDefault();
        const { username, password } = this.state;
        this.props.attemptLogIn({ username, password });
    }

    onSubmitPlay() {
        const { username, password } = this.state;
        this.props.attemptLogIn({ username, password }, true);
    }

    render() {
        const loginFormStyles = {
            flex: 2,
            margin: '2.5%',
            justifyContent: 'center'
        };
        const loginInputClass = 'login-form-input';

        return (
            <Form style={loginFormStyles}>
                <TextInput
                    ref={(input) => { this.usernameInput = input; }}
                    name='username'
                    class={loginInputClass}
                    placeholder='Username'
                    onChange={this.onUsernameInput}
                />
                <TextInput
                    type='password'
                    name='password'
                    autoComplete='current-password'
                    class={loginInputClass}
                    placeholder='Password'
                    onChange={this.onPasswordInput}
                />
                <Flex flex={1}>
                    <Button
                        disabled={!this.enableSubmit()}
                        class='login-btn login-form-btn transparent'
                        onClick={this.onSubmitLogin}
                        type='submit'
                    >
                        Log In
                    </Button>
                    <Button
                        disabled={!this.enableSubmit()}
                        class='play-btn login-form-btn transparent'
                        onClick={this.onSubmitPlay}
                    >
                        Play
                    </Button>
                </Flex>
            </Form>
        );
    }
}

LoginForm.propTypes = {
    attemptLogIn: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...authActions }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(LoginForm));
