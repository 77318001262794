import { Map } from 'immutable';

import util from '../../util';

const INIT_STATE = Map({
    assetUrl: util.getAssetUrl()
});

const types = {};

/* ********* Actions ********* */

const actions = {};

/* ********* Reducer ********* */

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

/* ********* Selectors ********* */

const utils = state => state.get('utils');

const selectors = {
    getAssetUrl: state => utils(state).get('assetUrl')
};

export {
    types as utilsTypes,
    actions as utilsActions,
    reducer as utilsReducer,
    selectors as utilsSelectors
};
