import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../../../../components/flex';

const UsernameLabel = (props) => {
    const chooseUsernameDotColor = () => {
        const {
            username,
            isChecking,
            isAvailable,
            isDelayed
        } = props;
        if (!username.length || isDelayed) {
            return 'white';
        }
        if (isChecking) {
            return 'yellow';
        }
        return isAvailable
            ? 'green'
            : 'red';
    };

    const chooseAlertLabel = () => {
        const {
            username,
            isChecking,
            isAvailable,
            isDelayed
        } = props;
        if (!username.length || isDelayed) {
            return '';
        }
        if (isChecking) {
            return 'Checking...';
        }
        return isAvailable
            ? 'Available'
            : 'Unavailable';
    };

    return (
        <Flex>
            <label htmlFor='username' style={{ margin: '2.5%', flex: 1 }}>Username</label>
            <div className='alert-label'>{chooseAlertLabel()}</div>
            <div className={`${chooseUsernameDotColor()} dot`} />
        </Flex>
    );
};

UsernameLabel.propTypes = {
    username: PropTypes.string.isRequired,
    isChecking: PropTypes.bool.isRequired,
    isAvailable: PropTypes.bool.isRequired,
    isDelayed: PropTypes.bool.isRequired
};

export default UsernameLabel;
