import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Flex from '../../../flex';

import './styles.css';

import util from '../../../../util';


class TextInput extends Component {
    constructor(props) {
        super(props);
        this.handleInput = this.handleInput.bind(this);
        this.state = {
            value: ''
        };
    }

    handleInput(e) {
        this.setState({ value: e.target.value }, () => {
            this.props.onChange(this.state.value);
        });
    }

    focus() {
        util.doAsync(() => this.textInput.focus());
    }

    render() {
        return (
            <Flex flex={1} flexDirection='column' justifyContent='center'>
                {this.props.label}
                <input
                    name={this.props.name}
                    ref={(input) => { this.textInput = input; }}
                    type={this.props.type || 'text'}
                    autoComplete={this.props.autoComplete || 'off'}
                    className={`${this.props.class} form-input`}
                    style={this.props.style}
                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    onChange={this.handleInput}
                />
            </Flex>
        );
    }
}

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    autoComplete: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.element,
    placeholder: PropTypes.string,
    class: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string
};

export default TextInput;
