import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const FlexContainer = (props) => {
    const flexStyles = {
        width: props.width,
        height: props.height,
        ...props.style
    };

    return (
        <div
            className={`${props.class || ''} flex-container`}
            style={{
                flex: props.flex,
                flexDirection: props.flexDirection,
                justifyContent: props.justifyContent,
                alignItems: props.alignItems,
                ...flexStyles
            }}
        >
            {props.children}
        </div>
    );
};

FlexContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.array
    ]),
    flex: PropTypes.number,
    flexDirection: PropTypes.string,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    class: PropTypes.string,
    style: PropTypes.object
};

export default FlexContainer;
