import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Flex from '../../../../components/flex';
import { MediumButton } from '../../../../components/button';

import './styles.css';

import util from '../../../../util';

class AccountField extends Component {
    constructor(props) {
        super(props);
        this.getLabel = this.getLabel.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.hideInput = this.hideInput.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            showInput: false
        };
    }

    focus() {
        util.doAsync(() => this.input && this.input.focus());
    }

    getLabel() {
        const { name } = this.props;
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    hideInput() {
        this.setState({ showInput: false });
    }

    handleClose() {
        this.props.handleClose(this.props.name);
    }

    handleClick() {
        this.setState({ showInput: true });
        this.focus();
    }

    handleChange() {
        const { name } = this.props;
        const { value } = this.input;
        this.props.handleChange(name, value);
    }

    render() {
        const {
            name,
            value,
            autoComplete,
            inputValue,
        } = this.props;
        const { showInput } = this.state;
        return (
            <Flex
                justifyContent='space-between'
                alignItems='center'
                style={{ margin: '1%' }}
            >
                <Flex flex={3} class='account-field'>
                    <label htmlFor={name}>{`${this.getLabel()}:`}</label>
                    <div style={{ paddingLeft: '5%' }}>{value}</div>
                </Flex>
                <Flex flex={2} justifyContent='flex-end'>
                    {showInput
                        ? (
                            <Flex alignItems='center'>
                                <div
                                    style={{ cursor: 'pointer', marginRight: '2.5%' }}
                                    onClick={this.handleClose}
                                >
                                    X
                                </div>
                                <input
                                    ref={(input) => { this.input = input; }}
                                    className='account-input'
                                    type={name === 'password' ? 'password' : 'text'}
                                    name={name}
                                    autoComplete={autoComplete || 'off'}
                                    placeholder=''
                                    value={inputValue}
                                    onChange={this.handleChange}
                                />
                            </Flex>
                        ) : (
                            <MediumButton
                                onClick={this.handleClick}
                                class='account-btn'
                            >
                                {`Change ${name}`}
                            </MediumButton>
                        )
                    }
                </Flex>
            </Flex>
        );
    }
}

AccountField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    autoComplete: PropTypes.string,
    inputValue: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default AccountField;
