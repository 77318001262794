import { Map } from 'immutable';

const INIT_STATE = Map({
    isLoggingIn: false,
    logInMessage: '',
    isLoggingOut: false,
    signUpMessage: '',
    isSigningUp: false,
    checkUsernameDelay: true,
    isCheckingUsername: false,
    usernameIsAvailable: false
});

const types = {
    attemptLogIn: 'ATTEMPT_LOG_IN',
    logInSuccess: 'LOG_IN_SUCCESS',
    logInFail: 'LOG_IN_FAIL',
    setLoggedIn: 'SET_LOGGED_IN',
    attemptLogOut: 'ATTEMPT_LOG_OUT',
    logOutSuccess: 'LOG_OUT_SUCCESS',
    logOutFail: 'LOG_OUT_FAIL',
    attemptSignUp: 'ATTEMPT_SIGN_UP',
    signUpSuccess: 'SIGN_UP_SUCCESS',
    signUpFail: 'SIGN_UP_FAIL',
    checkUsername: 'CHECK_USERNAME',
    setIsCheckingUsername: 'SET_IS_CHECKING_USERNAME',
    checkUsernameSuccess: 'CHECK_USERNAME_SUCCESS',
    checkUsernameFail: 'CHECK_USERNAME_FAIL',
    checkUsernameAbort: 'CHECK_USERNAME_ABORT',
    clearLogInMessage: 'CLEAR_LOG_IN_MESSAGE',
    clearSignUpMessage: 'CLEAR_SIGN_UP_MESSAGE',
};

/* ********* Actions ********* */

const actions = {
    attemptLogIn(data, playNow = false) {
        return {
            type: types.attemptLogIn,
            data,
            playNow
        };
    },
    attemptLogOut() {
        return {
            type: types.attemptLogOut
        };
    },
    attemptSignUp(data) {
        return {
            type: types.attemptSignUp,
            data
        };
    },
    setIsCheckingUsername() {
        return {
            type: types.setIsCheckingUsername
        };
    },
    checkUsername(data) {
        return {
            type: types.checkUsername,
            data
        };
    },
    clearLogInMessage() {
        return {
            type: types.clearLogInMessage
        };
    },
    clearSignUpMessage() {
        return {
            type: types.clearSignUpMessage
        };
    }
};

/* ********* Reducer ********* */

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
    case types.attemptLogIn:
        return state.merge({ isLoggingIn: true });
    case types.logInSuccess:
        return state.merge({
            isLoggingIn: false,
            logInMessage: action.data.message
        });
    case types.logInFail:
        return state.merge({
            isLoggingIn: false,
            logInMessage: action.data.message
        });
    case types.attemptLogOut:
        return state.merge({ isLoggingOut: true });
    case types.logOutSuccess:
        return state.merge({
            isLoggingOut: false
        });
    case types.logOutFail:
        return state.merge({
            isLoggingOut: false,
        });
    case types.attemptSignUp:
        return state.merge({ isSigningUp: true });
    case types.signUpSuccess:
        return state.merge({
            isSigningUp: false,
            signUpMessage: action.data.message,
        });
    case types.signUpFail:
        return state.merge({
            isSigningUp: false,
            signUpMessage: action.data.message
        });
    case types.checkUsername:
        return state.merge({ checkUsernameDelay: true });
    case types.setIsCheckingUsername:
        return state.merge({
            isCheckingUsername: true,
            checkUsernameDelay: false
        });
    case types.checkUsernameSuccess:
        return state.merge({
            isCheckingUsername: false,
            usernameIsAvailable: action.data.data.status,
            signUpMessage: action.data.message
        });
    case types.checkUsernameFail:
        return state.merge({
            isCheckingUsername: false,
            signUpMessage: action.data.message
        });
    case types.checkUsernameAbort:
        return state.merge({
            isCheckingUsername: false,
            signUpMessage: ''
        });
    case types.clearSignUpMessage:
        return state.merge({ signUpMessage: '' });
    case types.clearLogInMessage:
        return state.merge({ logInMessage: '' });
    default:
        return state;
    }
};

/* ********* Selectors ********* */

const auth = state => state.get('auth');

const selectors = {
    getLogInMessage: state => auth(state).get('logInMessage'),
    getSignUpMessage: state => auth(state).get('signUpMessage'),
    getIsCheckingUsername: state => auth(state).get('isCheckingUsername'),
    getUsernameAvailability: state => auth(state).get('usernameIsAvailable'),
    getCheckUsernameDelay: state => auth(state).get('checkUsernameDelay')
};

export {
    types as authTypes,
    actions as authActions,
    reducer as authReducer,
    selectors as authSelectors
};
