import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Flex from '../../../../components/flex';
import Form from '../../../../components/form';
import AccountField from '../account_field';
import { LargeButton } from '../../../../components/button';

import api from '../../../../services/api';

import './styles.css';

import { userActions } from '../../../../state/redux/user';

class AccountForm extends Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEmailInputClose = this.handleEmailInputClose.bind(this);
        this.handlePasswordInputClose = this.handlePasswordInputClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.state = {
            email: '',
            password: ''
        };
    }

    handleInputChange(prop, value) {
        this.setState({ [prop]: value });
    }

    handleEmailInputClose() {
        this.setState({ email: '' });
        this.emailField.hideInput();
    }

    handlePasswordInputClose() {
        this.setState({ password: '' });
        this.passwordField.hideInput();
    }

    async handleSave() {
        const { email } = this.props.account;
        const { setAccountMessage, saveUserAccount } = this.props;

        const newEmail = this.state.email;
        const newPassword = this.state.password;

        const accountData = {};

        if (newEmail && newEmail !== email) {
            accountData.email = newEmail;
        }
        if (newPassword) {
            accountData.password = newPassword;
        }

        if (!Object.keys(accountData).length) {
            setAccountMessage('No account information changed.');
            return;
        }

        const checkPassword = window.prompt('Please enter your password to continue:'); // eslint-disable-line

        if (!checkPassword) {
            setAccountMessage('Please try again.');
            return;
        }

        try {
            await api.logIn({ username: this.props.username, password: checkPassword });
        } catch (err) {
            setAccountMessage('Please try again.');
            return;
        }

        setAccountMessage('Saving account...');

        saveUserAccount(accountData);
        this.handleEmailInputClose();
        this.handlePasswordInputClose();
    }

    render() {
        const { account } = this.props;
        const { email, password } = this.state;
        return (
            <Form class='account-form'>
                <hr />
                <AccountField
                    ref={(emailField) => { this.emailField = emailField; }}
                    name='email'
                    value={account.email}
                    inputValue={email}
                    handleChange={this.handleInputChange}
                    handleClose={this.handleEmailInputClose}
                />
                <hr />
                <input
                    style={{ padding: 0, height: 0 }}
                    display='none'
                    name='username'
                    autoComplete='username'
                    existstomakepasswordmanagershappy='true'
                />
                <AccountField
                    ref={(passwordField) => { this.passwordField = passwordField; }}
                    name='password'
                    value='********'
                    autoComplete='new-password'
                    inputValue={password}
                    handleChange={this.handleInputChange}
                    handleClose={this.handlePasswordInputClose}
                />
                <hr />
                <Flex
                    justifyContent='space-between'
                    alignItems='flex-start'
                    style={{ margin: '1%' }}
                >
                    <Flex flex={1} class='account-field'>
                        <div>Date Created:</div>
                        <Flex flex={1} style={{ paddingLeft: '5%' }}>{account.dateCreated}</Flex>
                    </Flex>
                    <LargeButton onClick={this.handleSave} class='transparent save-btn'>Save</LargeButton>
                </Flex>
            </Form>
        );
    }
}

AccountForm.propTypes = {
    saveUserAccount: PropTypes.func.isRequired,
    setAccountMessage: PropTypes.func.isRequired,
    account: PropTypes.shape({
        displayName: PropTypes.string,
        email: PropTypes.string,
        dateCreated: PropTypes.string
    }).isRequired,
    username: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...userActions }, dispatch);

export default connect(null, mapDispatchToProps)(AccountForm);
