import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Flex from '../../../../components/flex';

import './styles.css';

class TeamCard extends Component {
    constructor(props) {
        super(props);
        this.showDescription = this.showDescription.bind(this);
        this.hideDescription = this.hideDescription.bind(this);
        this.state = {
            showDescription: false
        };
    }

    showDescription() {
        this.setState({ showDescription: true });
    }

    hideDescription() {
        this.setState({ showDescription: false });
    }

    renderDescription() {
        const { showDescription } = this.state;
        return showDescription
            ? (
                <Flex
                    flex={1}
                    flexDirection='column'
                    justifyContent='center'
                    class='card-description'
                >
                    {this.props.description}
                </Flex>
            ) : null;
    }

    render() {
        const {
            image,
            name,
            title,
            linkedin,
            runescape,
            github
        } = this.props;
        return (
            <Flex
                flexDirection='column'
                class='card-container'
            >
                <div className='card'>
                    <Flex
                        flexDirection='column'
                        style={{ position: 'relative' }}
                    >
                        <img
                            src={image}
                            className='card-img'
                            onMouseEnter={this.showDescription}
                            onMouseLeave={this.hideDescription}
                            onFocus={this.showDescription}
                            onBlur={this.hideDescription}
                            alt=''
                        />
                        {this.renderDescription()}
                    </Flex>
                    <Flex
                        alignItems='center'
                        flexDirection='column'
                        style={{ marginBottom: '2.5%' }}
                    >
                        <div className='card-name card-section'>{name}</div>
                        <div className='card-section' style={{ color: 'grey', fontSize: '0.8em' }}>{title}</div>
                        <div className='card-section'>
                            <a style={{ color: '#337ab7' }} href={linkedin} target='_blank' rel='noopener noreferrer'>LinkedIn</a>
                            {' | '}
                            <a style={{ color: '#337ab7' }} href={github} target='_blank' rel='noopener noreferrer'>GitHub</a>
                        </div>
                        <a href={runescape} target='_blank' rel='noopener noreferrer' className='card-section stats-btn'>Stats</a>
                    </Flex>
                </div>
            </Flex>
        );
    }
}

TeamCard.propTypes = {
    name: PropTypes.string,
    image: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    linkedin: PropTypes.string,
    github: PropTypes.string,
    runescape: PropTypes.string
};

export default TeamCard;
