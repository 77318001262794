import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../../../flex';

import './styles.css';

const navbarStyles = {
    paddingRight: '2.5%'
}

const NavbarRight = (props) => {
    return (
        <Flex
            flex={1}
            justifyContent='flex-end'
            style={navbarStyles}
        >
            {props.children}
        </Flex>
    );
};

NavbarRight.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.array
    ]).isRequired
};

export default NavbarRight;
