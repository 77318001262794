import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Form = (props) => {
    return (
        <form className={`${props.class || ''} column`} style={props.style} autoComplete='off'>
            {props.children}
        </form>
    );
};

Form.propTypes = {
    class: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ])
};

export default Form;
