import { Map } from 'immutable';

import util from '../../util';

const { toReadableDate } = util;

const INIT_STATE = Map({
    isFetching: false,
    username: '',
    displayName: '',
    account: Map({}),
    accountMessage: ''
});

const types = {
    fetchCurrentUser: 'FETCH_CURRENT_USER',
    fetchCurrentUserSuccess: 'FETCH_CURRENT_USER_SUCCESS',
    fetchCurrentUserFail: 'FETCH_CURRENT_USER_FAIL',
    fetchUserAccount: 'FETCH_USER_ACCOUNT',
    fetchUserAccountSuccess: 'FETCH_USER_ACCOUNT_SUCCESS',
    fetchUserAccountFail: 'FETCH_USER_ACCOUNT_FAIL',
    saveUserAccount: 'SAVE_USER_ACCOUNT',
    saveUserAccountSuccess: 'SAVE_USER_ACCOUNT_SUCCESS',
    saveUserAccountFail: 'SAVE_USER_ACCOUNT_FAIL',
    setAccountMessage: 'SET_ACCOUNT_MESSAGE',
    clearAccountMessage: 'CLEAR_ACCOUNT_MESSAGE'
};

/* ********* Actions ********* */

const actions = {
    fetchCurrentUser() {
        return {
            type: types.fetchCurrentUser
        };
    },
    fetchUserAccount() {
        return {
            type: types.fetchUserAccount
        };
    },
    saveUserAccount(data) {
        return {
            type: types.saveUserAccount,
            data
        };
    },
    setAccountMessage(data) {
        return {
            type: types.setAccountMessage,
            data
        };
    },
    clearAccountMessage() {
        return {
            type: types.clearAccountMessage
        };
    }
};

/* ********* Reducer ********* */

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
    case types.fetchCurrentUser:
        return state.merge({ isFetching: true });
    case types.fetchCurrentUserSuccess: {
        const { data } = action.data;
        return state.merge({
            isFetching: false,
            username: data.username,
            displayName: data.displayName
        });
    }
    case types.fetchCurrentUserFail:
        return state.merge({ isFetching: false });
    case types.fetchUserAccount:
        return state.merge({ isFetching: true });
    case types.fetchUserAccountSuccess: {
        const { data } = action.data;
        return state
            .setIn(['account'], Map({
                email: data.email,
                dateCreated: data.created_at
            }))
            .merge({ isFetching: false });
    }
    case types.fetchUserAccountFail:
        return state.merge({
            isFetching: false,
            accountMessage: action.data.message
        });
    case types.saveUserAccount:
        return state.merge({ isFetching: true });
    case types.saveUserAccountSuccess:
        return state.merge({
            isFetching: false,
            accountMessage: action.data.message
        });
    case types.saveUserAccountFail:
        return state.merge({
            isFetching: false,
            accountMessage: action.data.message
        });
    case types.setAccountMessage:
        return state.merge({ accountMessage: action.data });
    case types.clearAccountMessage:
        return state.merge({ accountMessage: '' });
    default:
        return state;
    }
};

/* ********* Selectors ********* */

const user = state => state.get('user');

const selectors = {
    getIsLoggedIn: state => user(state).get('username').length > 0,
    getCurrentUser: state => ({
        username: user(state).get('username'),
        displayName: user(state).get('displayName')
    }),
    getUserAccount: state => ({
        displayName: user(state).get('displayName'),
        email: user(state).getIn(['account', 'email']),
        dateCreated: toReadableDate(user(state).getIn(['account', 'dateCreated']))
    }),
    getAccountMessage: state => user(state).get('accountMessage')
};

export {
    types as userTypes,
    actions as userActions,
    reducer as userReducer,
    selectors as userSelectors
};
