import React from 'react';
import PropTypes from 'prop-types';

import { LargeButton } from '../../../../components/button';
import Flex from '../../../../components/flex';
import { getAssetUrl } from '../../../../../../build/shared/util';

import './styles.css';

const logo = `${getAssetUrl()}/images/web/logo/yellow_shadow.png`;

const TitleBanner = (props) => {
    const handleClick = () => {
        props.handlePlayClick();
    };

    const showButton = props.showButton;
    const renderPlayNowButton = () => {
        return (
            <LargeButton
                class={`play-now-btn transparent ${showButton ? 'fade-in' : 'fade-out'}`}
                onClick={handleClick}
            >
                Play Now
            </LargeButton>
        );
    };

    return (
        <Flex
            class='translucent'
            flex={6}
            flexDirection='column'
            justifyContent='space-around'
            alignItems='center'
        >
            <Flex flex={1.5} alignItems='center'>
                <img src={logo} alt='' className='chim-logo' />
            </Flex>
            <Flex flex={1} alignItems='center'>
                {renderPlayNowButton()}
            </Flex>
        </Flex>
    );
};

TitleBanner.propTypes = {
    showButton: PropTypes.bool.isRequired,
    handlePlayClick: PropTypes.func
};

export default TitleBanner;
