import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

export const Button = (props) => {
    const getSizeClass = () => {
        if (props.size === 'lg') {
            return 'btn-lg';
        }
        if (props.size === 'md') {
            return 'btn-md';
        }
        return '';
    };

    return (
        <button
            type={props.type || 'button'}
            className={`${props.class || ''} ${getSizeClass()} btn`}
            style={props.style}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.object,
    class: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string
};

export const LargeButton = props => <Button size='lg' {...props} />;

export const MediumButton = props => <Button size='md' {...props} />;
