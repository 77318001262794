import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../services/api';
import navigation from '../../services/navigation';
import { authTypes as types } from '../redux/auth';
import util from '../../util';

const { sagaDelay } = util;

function* attemptLogIn(action) {
    try {
        const response = yield call(api.logIn, action.data);
        yield put({ type: types.logInSuccess, data: response.data });

        yield sagaDelay(500);
        yield call(action.playNow
            ? navigation.toGame
            : navigation.reloadHomePage);
    } catch (e) {
        yield put({ type: types.logInFail, data: e.response.data });
    }
}

function* attemptLogOut() {
    try {
        yield call(api.logOut);
        yield put({ type: types.logOutSuccess });

        yield sagaDelay(500);
        yield call(navigation.reloadHomePage);
    } catch (e) {
        yield put({ type: types.logOutFail });
    }
}

function* attemptSignUp(action) {
    try {
        const response = yield call(api.signUp, action.data);
        yield put({ type: types.signUpSuccess, data: response.data });

        yield sagaDelay(500);
        yield call(navigation.reloadHomePage);
    } catch (e) {
        yield put({ type: types.signUpFail, data: e.response.data });
    }
}

function* checkUsername(action) {
    const { username } = action.data;
    try {
        yield sagaDelay(1000);
        if (!username.length) {
            yield put({ type: types.checkUsernameAbort });
        } else {
            yield put({ type: types.setIsCheckingUsername });
            const response = yield call(api.checkUsername, { username });
            yield put({ type: types.checkUsernameSuccess, data: response.data });
        }
    } catch (e) {
        yield put({ type: types.checkUsernameFail, data: e.response.data });
    }
}

export default function authSagas() {
    return [
        takeLatest(types.attemptLogIn, attemptLogIn),
        takeLatest(types.attemptLogOut, attemptLogOut),
        takeLatest(types.attemptSignUp, attemptSignUp),
        takeLatest(types.checkUsername, checkUsername)
    ];
}
