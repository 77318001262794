import client from './client';

const logIn = (credentials) => {
    return client.post('/login', credentials);
};

const signUp = (accountInfo) => {
    return client.post('/register', accountInfo);
};

const logOut = () => {
    return client.post('/logout');
};

const checkUsername = (username) => {
    return client.post('/check-username', username);
};

export default {
    logIn,
    logOut,
    signUp,
    checkUsername
};
