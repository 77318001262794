import Home from './home_page';
import Account from './account_page';
import SignUp from './signup_page';
import Team from './team_page';
import Changelog from './changelog_page';

export default {
    Home,
    Account,
    SignUp,
    Team,
    Changelog
};
