import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../flex';

import './styles.css';

const Small = (props) => {
    return (
        <Flex flexDirection='column' class={`${props.class || ''} panel-sm`} style={props.style}>
            {props.children}
        </Flex>
    );
};

Small.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.array
    ]).isRequired,
    class: PropTypes.string,
    style: PropTypes.object
};

const Large = (props) => {
    return (
        <Flex flexDirection='column' class={`${props.class || ''} panel-lg`} style={props.style}>
            {props.children}
        </Flex>
    );
};

Large.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.array
    ]).isRequired,
    class: PropTypes.string,
    style: PropTypes.object
};

export {
    Small as PanelSmall,
    Large as PanelLarge
};
