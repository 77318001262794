import React, { Component } from 'react';

import { PanelLarge } from '../../components/panel';
import Page from '../../components/page';
import Flex from '../../components/flex';

import api from '../../services/api';

import './styles.css';

class Changelog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            releases: []
        };
    }

    componentDidMount() {
        this.fetchChangelog();
    }

    async fetchChangelog() {
        try {
            this.setState({ isFetching: true });
            const response = await api.fetchChangelog();
            this.formatReleases(response.data);
        } finally {
            this.setState({ isFetching: false });
        }
    }

    formatReleases(data) {
        const releases = data.split('###');

        const formatted = releases
            .filter(release => release.length)
            .map((release) => {
                const lines = release.split('\n');
                return {
                    milestone: lines[0].trim(),
                    changes: lines
                        .splice(1)
                        .filter(line => line.length)
                        .map(line => line.substring(1).trim())
                };
            });
        this.setState({ releases: formatted });
    }

    renderChangelog() {
        const { releases, isFetching } = this.state;
        if (isFetching) {
            return <Flex class='loading'>Loading...</Flex>;
        }
        return releases.map(r => (
            <div key={r.milestone} className='release'>
                <hr />
                <h3 className='release-title'>{r.milestone}</h3>
                <ul className='release-body'>
                    {r.changes.map(value => (
                        <li key={value.substring(0, 16)}>{value}</li>
                    ))}
                </ul>
            </div>
        ));
    }

    render() {
        const panelStyles = {
            background: 'rgba(42, 49, 50, 0.5)',
            color: 'white',
            flex: 1
        };

        return (
            <Page
                title='Changelog | Age of Chim'
            >
                <Flex
                    flexDirection='column'
                    alignItems='center'
                    flex={1}
                >
                    <PanelLarge style={panelStyles}>
                        <h2 style={{ textAlign: 'center' }}>CHANGELOG</h2>
                        {this.renderChangelog()}
                    </PanelLarge>
                </Flex>
            </Page>
        );
    }
}

export default Changelog;
