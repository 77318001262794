import React from 'react';

import Flex from '../../../flex';
import { AltNavbar } from '../../../navbar';

import './styles.css';

const Footer = () => {
    const footerStyles = {
        fontSize: '20px',
        color: 'white',
        height: '80px',
        minHeight: '80px'
    };

    return (
        <Flex style={footerStyles}>
            <AltNavbar />
        </Flex>
    );
};

export default Footer;
