import history from './history';

export default {
    toHomePage() {
        history.push('/');
    },
    toAccountPage() {
        history.push('/account');
    },
    toSignUpPage() {
        history.push('/signup');
    },
    toTeamPage() {
        history.push('/team');
    },
    toChangelogPage() {
        history.push('/changelog');
    },
    toGame() {
        window.location = '/play';
    },
    reloadHomePage() {
        window.location = '/';
    }
};