import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Flex from '../flex';
import Content from './components/content';
import Footer from './components/footer';
import Header from './components/header';

import { utilsSelectors } from '../../state/redux/utils';

import './styles.css';

import constants from '../../constants';

class Page extends Component {
    componentDidMount() {
        this.setTitle();
    }

    setTitle() {
        document.title = this.props.title;
    }

    render() {
        const assetUrl = this.props.assetUrl;
        return (
            <Flex
                flexDirection='column'
                width='100vw'
                height='100vh'
                overflow='auto'
                class='page'
                style={{ backgroundImage: `url('${assetUrl}/${constants.IMAGE.BACKGROUND}')` }}
            >
                <Header />
                <Content>
                    {this.props.children}
                </Content>
                <Footer />
            </Flex>
        );
    }
}

Page.propTypes = {
    title: PropTypes.string.isRequired,
    assetUrl: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired
};

const mapStateToProps = (state) => {
    return {
        assetUrl: utilsSelectors.getAssetUrl(state)
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Page);
