import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Page from '../../components/page';
import Flex from '../../components/flex';
import { PanelLarge } from '../../components/panel';

import ChimProfile from './components/chim_profile';

import { userSelectors, userActions } from '../../state/redux/user';

class Account extends Component {
    componentWillUnmount() {
        this.props.clearAccountMessage();
    }

    render() {
        const panelStyles = {
            maxWidth: '900px',
            borderRadius: '10px',
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
            background: 'rgba(42, 49, 50, 0.5)',
            color: 'white'
        };

        return (
            <Page
                title='Account | Age of Chim'
            >
                <Flex
                    flexDirection='column'
                    alignItems='center'
                    flex={1}
                >
                    <PanelLarge style={panelStyles}>
                        <h2 style={{ textAlign: 'center' }}>Account</h2>
                        <hr />
                        <ChimProfile />
                        <Flex
                            flex={1}
                            textAlign='center'
                            justifyContent='center'
                            class='fade-in'
                            style={{ margin: '1%' }}
                        >
                            {this.props.accountMessage}
                        </Flex>
                    </PanelLarge>
                </Flex>
            </Page>
        );
    }
}

Account.propTypes = {
    accountMessage: PropTypes.string,
    clearAccountMessage: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        accountMessage: userSelectors.getAccountMessage(state),
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...userActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Account);
