import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { userSelectors } from '../../state/redux/user';

const AuthNever = (props) => {
    return props.isLoggedIn ? null : props.children;
};

AuthNever.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: userSelectors.getIsLoggedIn(state)
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuthNever);
