import sharedConstants from '../../build/shared/constants';

const constants = {
    ...sharedConstants,
    IMAGE: {
        BACKGROUND: 'images/web/chim-landscape.jpg',
        CHIM_PROF_PIC: 'images/web/chim-prof-pic.jpg'
    }
};

export default constants;
