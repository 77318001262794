import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import './styles.css';

const NavbarLink = (props) => {
    return (
        <NavLink
            className='navbar-item navbar-link'
            to={props.href}
        >
            {props.children}
        </NavLink>
    );
};

NavbarLink.propTypes = {
    children: PropTypes.string.isRequired,
    href: PropTypes.string
};

const NavbarLinkBlue = (props) => {
    return (
        <NavLink
            className='navbar-item navbar-link-blue'
            to={props.href}
        >
            {props.children}
        </NavLink>
    );
};

NavbarLinkBlue.propTypes = {
    children: PropTypes.string.isRequired,
    href: PropTypes.string
};

const NavbarButton = (props) => {
    return (
        <button
            className='navbar-item navbar-btn'
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};

NavbarButton.propTypes = {
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

const NavbarText = props => <span className='navbar-item'>{props.children}</span>;

NavbarText.propTypes = {
    children: PropTypes.string.isRequired
};

export {
    NavbarLink,
    NavbarLinkBlue,
    NavbarButton,
    NavbarText
};
