import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../../../flex';

import './styles.css';

const Content = (props) => {
    return (
        <Flex
            flex={1}
            flexDirection='column'
            class='content'
        >
            {props.children}
        </Flex>
    );
};

Content.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired
};

export default Content;
