import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../../../../components/flex';

const ConfirmPasswordLabel = (props) => {
    const choosePasswordDotColor = () => {
        const { password1, password2 } = props;
        if (!password1.length || !password2.length) {
            return 'white';
        }
        return password1 === password2
            ? 'green'
            : 'red';
    }

    return (
        <Flex>
            <label htmlFor='password2' style={{ margin: '2.5%', flex: 1 }}>Confirm Password</label>
            <div className={`${choosePasswordDotColor()} dot`} />
        </Flex>
    );
};

ConfirmPasswordLabel.propTypes = {
    password1: PropTypes.string.isRequired,
    password2: PropTypes.string.isRequired
};

export default ConfirmPasswordLabel;
