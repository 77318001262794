import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { LargeButton } from '../../../../components/button';
import Flex from '../../../../components/flex';
import Form from '../../../../components/form';
import TextInput from '../../../../components/form/components/text_input';
import UsernameLabel from '../username_label';
import ConfirmPasswordLabel from '../confirm_password_label';

import './styles.css';

import { authActions, authSelectors } from '../../../../state/redux/auth';

class SignUpForm extends Component {
    constructor(props) {
        super(props);
        this.onUsernameInput = this.onUsernameInput.bind(this);
        this.onEmailInput = this.onEmailInput.bind(this);
        this.onPassword1Input = this.onPassword1Input.bind(this);
        this.onPassword2Input = this.onPassword2Input.bind(this);
        this.onSubmitSignUp = this.onSubmitSignUp.bind(this);
        this.state = {
            username: '',
            email: '',
            password1: '',
            password2: '',
        };
    }

    componentDidMount() {
        this.usernameInput.focus();
    }

    onUsernameInput(value) {
        const { clearSignUpMessage, checkUsername } = this.props;
        clearSignUpMessage();
        this.setState({ username: value }, () => {
            checkUsername({ username: this.state.username });
        });
    }

    onEmailInput(value) {
        this.props.clearSignUpMessage();
        this.setState({ email: value });
    }

    onPassword1Input(value) {
        this.props.clearSignUpMessage();
        this.setState({ password1: value });
    }

    onPassword2Input(value) {
        this.props.clearSignUpMessage();
        this.setState({ password2: value });
    }

    enableSubmit() {
        const {
            username,
            email,
            password1,
            password2
        } = this.state;
        const fieldsExist = username.length && email.length
            && password1.length && password2.length;

        const passwordsMatch = password1 === password2;

        return (fieldsExist && passwordsMatch);
    }

    onSubmitSignUp(e) {
        e.preventDefault();
        const {
            username,
            email,
            password1,
            password2
        } = this.state;

        this.props.attemptSignUp({
            username,
            email,
            password1,
            password2
        });
    }

    renderUsernameLabel() {
        const {
            isCheckingUsername,
            usernameIsAvailable,
            checkUsernameDelay
        } = this.props;
        return (
            <UsernameLabel
                username={this.state.username}
                isDelayed={checkUsernameDelay}
                isChecking={isCheckingUsername}
                isAvailable={usernameIsAvailable}
            />
        );
    }

    renderConfirmPasswordLabel() {
        const { password1, password2 } = this.state;
        return (
            <ConfirmPasswordLabel
                password1={password1}
                password2={password2}
            />
        );
    }

    render() {
        const signUpFormStyles = {
            flex: 6,
            margin: '2.5%'
        };
        const signUpInputClass = 'sign-up-input';

        return (
            <Form style={signUpFormStyles}>
                <TextInput
                    name='username'
                    ref={(input) => { this.usernameInput = input; }}
                    placeholder='-'
                    label={this.renderUsernameLabel()}
                    class={signUpInputClass}
                    onChange={this.onUsernameInput}
                />
                <TextInput
                    name='email'
                    placeholder='-'
                    label={<label htmlFor='email' style={{ margin: '2.5%' }}>Email</label>}
                    class={signUpInputClass}
                    onChange={this.onEmailInput}
                />
                <TextInput
                    name='password1'
                    placeholder='-'
                    type='password'
                    label={<label htmlFor='password1' style={{ margin: '2.5%' }}>Password</label>}
                    class={signUpInputClass}
                    onChange={this.onPassword1Input}
                />
                <TextInput
                    name='password2'
                    placeholder='-'
                    type='password'
                    label={this.renderConfirmPasswordLabel()}
                    class={signUpInputClass}
                    onChange={this.onPassword2Input}
                />
                <Flex flex={1} justifyContent='flex-end' alignItems='flex-end'>
                    <LargeButton
                        disabled={!this.enableSubmit()}
                        class='sign-up-btn transparent'
                        onClick={this.onSubmitSignUp}
                        type='submit'
                    >
                        Sign Up
                    </LargeButton>
                </Flex>
            </Form>
        );
    }
}

SignUpForm.propTypes = {
    attemptSignUp: PropTypes.func.isRequired,
    checkUsername: PropTypes.func.isRequired,
    usernameIsAvailable: PropTypes.bool.isRequired,
    isCheckingUsername: PropTypes.bool.isRequired,
    clearSignUpMessage: PropTypes.func.isRequired,
    checkUsernameDelay: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        usernameIsAvailable: authSelectors.getUsernameAvailability(state),
        isCheckingUsername: authSelectors.getIsCheckingUsername(state),
        checkUsernameDelay: authSelectors.getCheckUsernameDelay(state)
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...authActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
