import client from './client';

const fetchCurrentUser = () => client.get('/current-user');

const fetchUserAccount = () => client.get('/my-account');

const saveUserAccount = accountData => client.put('/save-account', accountData);

export default {
    fetchCurrentUser,
    fetchUserAccount,
    saveUserAccount
};
