import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Flex from '../flex';
import AuthNever from '../../containers/auth_never';
import AuthOnly from '../../containers/auth_only';
import NavbarLeft from './components/navbar_left';
import NavbarRight from './components/navbar_right';
import {
    NavbarLink,
    NavbarLinkBlue,
    NavbarText,
    NavbarButton
} from './components/navbar_item';

import './styles.css';

import { authActions } from '../../state/redux/auth';

const Main = (props) => {
    const currentPath = props.location.pathname;

    return (
        <Flex
            flex={1}
            alignItems='center'
            class={currentPath === '/' ? 'translucent' : ''}
        >
            <NavbarLeft>
                <NavbarLink href='/'>home</NavbarLink>
            </NavbarLeft>
            <NavbarRight>
                <AuthNever>
                    <NavbarLink href='/signup'>create an account</NavbarLink>
                </AuthNever>
                <AuthOnly>
                    <NavbarLink href='/account'>account</NavbarLink>
                </AuthOnly>
                <AuthOnly>
                    <NavbarButton onClick={props.attemptLogOut}>logout</NavbarButton>
                </AuthOnly>
            </NavbarRight>
        </Flex>
    );
};

Main.propTypes = {
    attemptLogOut: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...authActions }, dispatch);

export const MainNavbar = withRouter(connect(null, mapDispatchToProps)(Main));

const Alt = () => {
    return (
        <Flex flex={1} alignItems='center'>
            <NavbarLeft>
                <NavbarText>© 2023 Brethren Studios</NavbarText>
            </NavbarLeft>
            <NavbarRight>
                <NavbarLinkBlue href='/team'>about the team</NavbarLinkBlue>
                <NavbarLinkBlue href='/changelog'>changelog</NavbarLinkBlue>
            </NavbarRight>
        </Flex>
    );
};

export const AltNavbar = Alt;
