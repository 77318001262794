import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../services/api';
import { userTypes as types } from '../redux/user';

function* fetchCurrentUser() {
    try {
        const response = yield call(api.fetchCurrentUser);
        yield put({ type: types.fetchCurrentUserSuccess, data: response.data });
    } catch (e) {
        yield put({ type: types.fetchCurrentUserFail, data: e.response.data });
    }
}

function* fetchUserAccount() {
    try {
        const response = yield call(api.fetchUserAccount);
        yield put({ type: types.fetchUserAccountSuccess, data: response.data });
    } catch (e) {
        yield put({ type: types.fetchUserAccountFail, data: e.response.data });
    }
}

function* saveUserAccount(action) {
    try {
        let response = yield call(api.saveUserAccount, action.data);
        yield put({ type: types.saveUserAccountSuccess, data: response.data });

        response = yield call(api.fetchUserAccount, action.data);
        yield put({ type: types.fetchUserAccountSuccess, data: response.data });
    } catch (e) {
        yield put({ type: types.saveUserAccountFail, data: e.response.data });
    }
}

export default function userSagas() {
    return [
        takeLatest(types.fetchCurrentUser, fetchCurrentUser),
        takeLatest(types.fetchUserAccount, fetchUserAccount),
        takeLatest(types.saveUserAccount, saveUserAccount)
    ];
}
