import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Map } from 'immutable';

import reducer from './redux';
import sagas from './sagas';

// create saga middleware
const sagaMiddleware = createSagaMiddleware();

// compose enhancers
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
    applyMiddleware(sagaMiddleware)
);

// set initial state
const defaultState = Map();

const store = createStore(
    reducer,
    defaultState,
    enhancer
);

sagaMiddleware.run(sagas);

export default store;
