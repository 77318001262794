import React from 'react';

import Flex from '../../../flex';
import { MainNavbar } from '../../../navbar';

import './styles.css';

const Header = () => {
    const headerStyles = {
        fontSize: '20px',
        color: 'white',
        height: '80px',
        minHeight: '80px'
    };

    return (
        <Flex style={headerStyles}>
            <MainNavbar />
        </Flex>
    );
};

export default Header;
