import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Page from '../../components/page';
import Flex from '../../components/flex';
import { PanelSmall } from '../../components/panel';
import SignUpForm from './components/signup_form';
import { authSelectors, authActions } from '../../state/redux/auth';
import { userSelectors } from '../../state/redux/user';

class SignUp extends Component {
    componentWillUnmount() {
        this.props.clearSignUpMessage();
    }

    render() {
        const panelStyles = {
            maxWidth: '500px',
            borderRadius: '10px',
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
            background: 'rgba(42, 49, 50, 0.5)',
            color: 'white'
        };

        return (
            <Page
                title='Sign Up | Age of Chim'
            >
                <Flex
                    flexDirection='column'
                    alignItems='center'
                    flex={1}
                >
                    <PanelSmall style={panelStyles}>
                        <SignUpForm />
                        <Flex
                            flex={0.5}
                            textAlign='center'
                            justifyContent='center'
                            class='fade-in'
                            style={{ margin: '2.5%' }}
                        >
                            {this.props.signUpMessage}
                        </Flex>
                    </PanelSmall>
                </Flex>
            </Page>
        );
    }
}

SignUp.propTypes = {
    signUpMessage: PropTypes.string,
    clearSignUpMessage: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        signUpMessage: authSelectors.getSignUpMessage(state),
        isLoggedIn: userSelectors.getIsLoggedIn(state)
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...authActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
