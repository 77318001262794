import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Route, Redirect } from 'react-router-dom';
import { userSelectors } from '../../state/redux/user';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            rest.isLoggedIn
                ? <Component {...props} />
                : <Redirect to='/' />
        )}
    />
);

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.element.isRequired,
        PropTypes.func.isRequired
    ]),
    isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: userSelectors.getIsLoggedIn(state)
    };
};

export default connect(mapStateToProps, null)(PrivateRoute);
