import React from 'react';

import TeamCard from './components/team_card';
import Page from '../../components/page';
import Flex from '../../components/flex';

import './styles.css';

const Team = () => {
    return (
        <Page
            title='Team | Age of Chim'
        >
            <Flex
                flex={1}
                flexDirection='column'
                class='translucent'
            >
                <h2 style={{ textAlign: 'center', color: 'white' }}>ABOUT THE TEAM</h2>
                <hr />
                <Flex
                    flex={1}
                    justifyContent='center'
                    style={{
                        flexWrap: 'wrap',
                        marginLeft: '5%',
                        marginRight: '5%'
                    }}
                >
                    <TeamCard
                        name='Evan Williams'
                        image='https://d29477g74vzgh5.cloudfront.net/images/web/evan-princeton.jpeg'
                        description={`Evan Williams is a soon-to-be graduate of UC Berkeley with a B.A. in Computer Science.
                            He is the lead designer of Age of Chim and is the owner of the Age of Chim domain and application
                            server. His responsibilities include client- and server-side application development, development
                            workflow construction, game design, lore and content contribution, and providing some of the game's 
                            graphics, among other things. He enjoys agile workflows, deep lore, decline bench, Old School
                            Runescape, GameCube, Bush Beans, Family Guy, fly hats, freestyle runs, and a cold Coors Light.`}
                        title='Lead Designer & Founder'
                        linkedin='https://www.linkedin.com/in/evan-williams-5a4201108/'
                        github='https://github.com/evanw555'
                        runescape='https://crystalmathlabs.com/tracker/view_stats.php?player=cowberry5'
                    />
                    <TeamCard
                        name='David Vorona'
                        image='https://d29477g74vzgh5.cloudfront.net/images/web/david-consultant.jpeg'
                        description='David Vorona is a graduate of the University of Virginia, where he was a student of Economics
                            and the Russian language. Initially a hobby, programming now takes up most of his time, and his prime
                            directives on the Age of Chim include web development across the stack, overall game design, and content
                            creation. He is a common fixture at the local Wingstop, and is universally admired for his freestyling prowess
                            and quest cape in Old School Runescape.'
                        title='Full Stack Development'
                        linkedin='https://www.linkedin.com/in/davidvorona/'
                        github='https://github.com/davidvorona'
                        runescape='https://crystalmathlabs.com/tracker/view_stats.php?player=Vijuhas'
                    />
                    <TeamCard
                        name='Dick Ridley'
                        image='https://d29477g74vzgh5.cloudfront.net/images/web/dick-richboi.jpeg'
                        description={`Richard Ridley is a 2017 graduate of Stanford University with a B.S. in Computer Science and
                            minor in Economics. He is a recent addition to the Age of Chim team and his primary responsibilities
                            will include server-side development and algorithm design as well as providing vibes and contributing to
                            discussions about game design. He is an avid consumer of Coors Light, Five Guy's Burgers and Fries, and
                            baked potatoes. In his free time, he enjoys training agility in Old School Runescape, watching Game of Thrones,
                            and solving crossword puzzles.`}
                        title='Back End Development'
                        linkedin='https://www.linkedin.com/in/richard-ridley-92699799/'
                        github='https://github.com/rpridley'
                        runescape='https://crystalmathlabs.com/tracker/view_stats.php?player=dickram'
                    />
                    <TeamCard
                        name='Jake Barber'
                        image='https://d29477g74vzgh5.cloudfront.net/images/web/jake-fresh.jpg'
                        description={`Jake Barber is a graduate of the University of Colorado at Boulder, where he studied Integrative Physiology.
                            He is self taught in adaptive and responsive web design. His role on the Chim team involves developing the front
                            end platform of the game's website, which includes the front page, about the team and account management sections.
                            He enjoys running, rock climbing, Jimmy Dean Sausages, jamming with his band, and woodcutting in Old School Runescape.`}
                        title='Front End Development'
                        linkedin='https://www.linkedin.com/in/jake-barber-440342164/'
                        github='https://github.com/jaba9531'
                        runescape='https://crystalmathlabs.com/tracker/view_stats.php?player=big_belly'
                    />
                    <TeamCard
                        name='Kellie Williams'
                        image='https://d29477g74vzgh5.cloudfront.net/images/web/kellie.jpg'
                        description={`Kellie Williams is a student at Cal Poly, San Luis Obispo studying Graphic Communications. She is the artistic
                            talent behind Age of Chim and is the original creator of the deep Chim lore, which began with her first, monumental work,
                            'Chim's First Christmas' back in the early 2000's. She enjoys fluffy cats, has never played Old School Runescape, and does
                            not have a fondness for Coors Light in any form.`}
                        title='Graphic Designer'
                        linkedin=''
                        github=''
                        runescape='https://crystalmathlabs.com/tracker/track.php?player=zezima'
                    />
                </Flex>
            </Flex>
        </Page>
    );
};

export default Team;
